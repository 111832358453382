import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import calendarIcon from "../../assets/calendar-plus-02.svg";

import { Button } from "../../components/buttons/Button";
import { SmallSummaryCard } from "../../components/cards/SmallSummaryCard";
import { FormData } from "../../types";
import { submitForm } from "../../services";
import { Input } from "../../components/input/Input";

interface UserDataStepProps {
  setStep: () => void;
  setStepFormData: (key: string, val: any) => void;
  formData: FormData;
  getPDFid: (val: string) => void;
}

export const UserDataStep = ({
  setStep,
  setStepFormData,
  formData,
  getPDFid,
}: UserDataStepProps) => {
  const { fullName, email, phone, agreementForMarketing, agreementOfStatute } = formData;
  const [showStatute, setShowStatute] = useState(false);
  const [agreementOfStatuteError, setAgreementOfStatuteError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [generalError, setGeneralError] = useState(false);

  useEffect(() => {
    if (agreementOfStatute) {
      setAgreementOfStatuteError(false);
    }
    if (document !== null) {
      const checkBox = document.getElementById("my-checkbox") as {
        indeterminate: any;
      } | null;
      if (checkBox) {
        if (agreementForMarketing || agreementOfStatute) {
          checkBox.indeterminate = true;
        }
        if (
          (agreementForMarketing && agreementOfStatute) ||
          (!agreementForMarketing && !agreementOfStatute)
        ) {
          checkBox.indeterminate = false;
        }
      }
    }
  }, [agreementOfStatute, agreementForMarketing]);

  const { refetch, ...confirmationDataOther } = useQuery({
    queryKey: ["confirmation"],
    queryFn: () => {
      return submitForm(
        formData.vehiclePlates,
        formData.vehicleType,
        formData.service,
        formData.selectedDeposit,
        formData.selectedDate,
        formData.email,
        formData.fullName,
        formData.phone.substring(3)
      ).then((response) => {
        if (response.message === "OK") {
          setStep();
          getPDFid(response.data);
          setGeneralError(false);
        } else {
          setGeneralError(true);
        }
        return response;
      });
    },
    enabled: false,
    retry: false,
  });

  const isValidEmail = (val: string = email) => {
    const emailRegex = /\w+@\w+\.\w+/;
    return emailRegex.test(val) && val !== "";
  };
  const isValidPhone = (val: string = phone) => {
    const phoneNumberRegex = /^\+48\d{9}$/;
    return phoneNumberRegex.test(val.split(" ").join("")) && val !== "";
  };
  const isValidFullName = (val: string = fullName) => {
    const fullNameRegex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]*$/;
    return fullNameRegex.test(val) && val.length > 2;
  };

  const onClick = () => {
    if (agreementOfStatute && isValidEmail() && isValidFullName() && isValidPhone()) {
      refetch();
    } else {
      setAgreementOfStatuteError(!agreementOfStatute);
      setEmailError(!isValidEmail());
      setFullNameError(!isValidFullName());
      setPhoneError(!isValidPhone());
    }
  };

  return (
    <div className="px-4 lg:max-w-[1440px] lg:mx-auto">
      <div className="lg:flex gap-5 justify-between mt-5">
        <div className="lg:max-w-[800px] w-full ">
          <div className="text-2xl font-bold text-[#344054] mb-5">Dane do rezerwacji</div>
          <div className="space-y-5">
            <Input
              label="Imię i nazwisko*"
              value={fullName}
              autocorrect="on"
              autocomplete="name"
              type="text"
              autocapitalize="on"
              onChange={(e) => {
                setFullNameError(!isValidFullName(e.target.value));
                setStepFormData("fullName", e.target.value);
              }}
              placeholder=""
              isError={fullNameError}
              errorMessage="Wpisz imię i nazwisko"
            />
            <Input
              type="email"
              autocapitalize="off"
              autocorrect="off"
              autocomplete="email"
              label="E-mail*"
              value={email}
              onChange={(e) => {
                setEmailError(!isValidEmail(e.target.value));
                setStepFormData("email", e.target.value);
              }}
              placeholder=""
              isError={emailError}
              errorMessage="Wpisz adres email"
            />
            <Input
              type="tel"
              autocapitalize="off"
              autocorrect="off"
              autocomplete="tel"
              label="Telefon*"
              value={phone || "+48"}
              onChange={(e) => {
                let tempPhone: string = "";
                if (e.target.value.length < 3) {
                  return;
                }
                if (!e.target.value.includes("+48")) {
                  tempPhone = "+48" + e.target.value;
                } else {
                  tempPhone = e.target.value;
                }
                if (tempPhone.length <= 12) {
                  setPhoneError(!isValidPhone(tempPhone));
                  setStepFormData("phone", tempPhone);
                }
              }}
              placeholder="+48"
              isError={phoneError}
              errorMessage="Wpisz numer telefonu"
            />
          </div>
          <div className="mt-4 space-y-4 max-w-[800px]">
            <div
              className="flex gap-3 cursor-pointer"
              onClick={() => {
                if (!agreementOfStatute || !agreementForMarketing) {
                  setStepFormData("agreementOfStatute", true);
                  setStepFormData("agreementForMarketing", true);
                } else {
                  setStepFormData("agreementOfStatute", false);
                  setStepFormData("agreementForMarketing", false);
                }
              }}
            >
              <input
                checked={agreementOfStatute && agreementForMarketing}
                type="checkbox"
                className={`checkbox mt-[1px] h-5 w-5 [--chkbg:#03BD3F] [--chkfg:#FFF] border-grayMain checked:border-greenHover rounded-md ${
                  agreementOfStatute || agreementForMarketing
                    ? "lg:hover:shadow-inputHoverGreen"
                    : "lg:hover:shadow-inputHoverGray"
                }`}
                onChange={() => {}}
                id="my-checkbox"
              />
              <div className="font-semibold">Zaznacz wszystkie</div>
            </div>
            <div>
              <div
                className="flex gap-3 cursor-pointer"
                onClick={() => {
                  setStepFormData("agreementOfStatute", !agreementOfStatute);
                }}
              >
                <input
                  checked={agreementOfStatute}
                  type="checkbox"
                  onChange={() => {}}
                  className={`checkbox mt-[1px] h-5 w-5 [--chkbg:#03BD3F] [--chkfg:#FFF] border-grayMain checked:border-greenHover rounded-md ${
                    agreementOfStatute
                      ? "lg:hover:shadow-inputHoverGreen"
                      : "lg:hover:shadow-inputHoverGray"
                  }`}
                />
                <div>
                  Oświadczam iż akceptuję regulamin rezerwacji*
                  <span
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowStatute((prevState) => !prevState);
                    }}
                  >
                    (Pokaż)
                  </span>
                </div>
              </div>
              {agreementOfStatuteError && (
                <div className="text-sm text-redMain ml-8">Zgoda jest wymagana</div>
              )}
            </div>

            {showStatute && (
              <div className="text-sm space-y-1 px-8">
                <p>
                  1. Zadaniem usługi jest umożliwienie rezerwacji za pośrednictwem sieci
                  Internet wolnego stanowiska w serwisie opon w/w firmy, o ustalonej
                  godzinie w ustalonym dniu
                </p>
                <p>
                  2. Rezerwacji jest bezpłatna i może jej dokonać każdy, kto posiada
                  dostęp do Internetu.
                </p>
                <p>
                  3. Warunkiem poprawnej rezerwacji jest wprowadzenie imienia i nazwiska
                  rezerwującego, telefonu kontaktowego, numeru rejestracyjnego pojazdu
                  oraz typu pojazdu. Numer telefonu może być wykorzystywany do celów
                  marketingowych.
                </p>
                <p>
                  4. Zastrzegamy sobie możliwość telefonicznego potwierdzenia rezerwacji
                  stanowiska, choć nie jest ono konieczne
                </p>
                <p>
                  5. Każde spóźnienie powoduje, że termin rezerwacji przepada. Konieczne
                  jest ustalenie nowego, już w standardowym trybie.
                </p>
                <p>
                  6. Warunkiem koniecznym do terminowego wykonania usługi wymiany opon,
                  jest posiadanie zlecenia, które musi być otwarte w BOK przed ustaloną
                  godziną wymiany opon.
                </p>
                <p>
                  7. Z uwagi na różnorodność pojazdów, które obsługujemy, a także ze
                  względu na ich stan techniczny, terminowość usługi może ulec zmianie.
                </p>
                <p>
                  8. Numer rejestracyjny pojazdu klienta, który nie pojawił się, bądź
                  spóźnił na umówioną godzinę może być przy kolejnych próbach rezerwacji
                  odrzucony przez operatora systemu.
                </p>
              </div>
            )}
            <div
              className="flex gap-3 max-w-[550px] cursor-pointer"
              onClick={() => {
                setStepFormData("agreementForMarketing", !agreementForMarketing);
              }}
            >
              <input
                checked={agreementForMarketing}
                type="checkbox"
                className={`checkbox mt-[1px] h-5 w-5 [--chkbg:#03BD3F] [--chkfg:#FFF] border-grayMain checked:border-greenHover rounded-md ${
                  agreementForMarketing
                    ? "lg:hover:shadow-inputHoverGreen"
                    : "lg:hover:shadow-inputHoverGray"
                }`}
                onChange={() => {}}
              />
              Wyrażam zgodę na wykorzystywanie powyższych danych osobowych do celów
              marketingowo-reklamowych związanych z ofertami firmy
            </div>
          </div>
          <div className="pb-10">
            <Button onClick={onClick} btnClassName="mt-5 ">
              {confirmationDataOther.isFetching ? (
                <div className="flex justify-center">
                  <span className="loading loading-dots loading-md text-white"></span>
                </div>
              ) : (
                <>
                  <img src={calendarIcon} />
                  Rezerwuj wizyte
                </>
              )}
            </Button>
            {generalError && (
              <div className="text-sm text-redMain mt-3">
                Coś poszło nie tak. Sprawdź czy wszytkie dane w formularzu są poprawne
              </div>
            )}
          </div>
        </div>

        <div className="hidden lg:block">
          <SmallSummaryCard formData={formData} />
        </div>
      </div>
    </div>
  );
};
