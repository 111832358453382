import { useState } from "react";

import logo from "./assets/logo.png";
import backArrow from "./assets/arrow-narrow-left.svg";

import { StepsIndicator } from "./components/navigation/StepsIndicator";
import { ServicesStep } from "./pages/ServicesStep/ServicesStep";
import { DatesStep } from "./pages/DatesStep/DatesStep";
import { UserDataStep } from "./pages/UserDataStep/UserDataStep";
import { ConfirmationStep } from "./pages/ConfirmationStep/ConfirmationStep";
import { FormData } from "./types";
import { getIsValidDepositPlates } from "./utils";

function App() {
  const [step, setStep] = useState<number>(1);
  const [PDFId, setPDFId] = useState<string>("");
  const [formData, setFormData] = useState<FormData>({
    agreementForMarketing: false,
    agreementOfStatute: false,
    email: "",
    fullName: "",
    phone: "",
    selectedDate: null,
    selectedDeposit: undefined,
    service: undefined,
    vehiclePlates: "",
    vehicleType: null,
    vehicleTypeName: null,
  });

  const setFormDataByKey = (key: string, val: any) => {
    setFormData((prevState) => ({ ...prevState, [key]: val }));
  };

  const isValidServiceStep = ![
    formData.service === undefined,
    formData.vehiclePlates.length < 6,
    formData.vehicleType === null,
    !getIsValidDepositPlates(formData.selectedDeposit),
  ].includes(true);

  const isValidDateStep = formData.selectedDate !== null;

  return (
    <div className="font-inter text-textMain bg-bgBlue">
      <div className="bg-white shadow-headerShadow">
        <div className="p-4 lg:max-w-[1440px] lg:mx-auto lg:flex lg:justify-between lg:items-center lg:p-6">
          <div className="flex lg:gap-3 lg:justify-start justify-between items-center">
            <a
              href={"https://www.oponyserwis.pl"}
              className="w-[60%] max-w-[200px] sm:max-w-[300px] lg:max-w-[200px] xl:max-w-[300px]"
            >
              <img src={logo} />
            </a>
            <div className="flex items-center">
              <div className="border-l-2 border-greenMain rotate-[15deg] h-[35px] w-1"></div>
              <div className="text-xs sm:text-sm font-medium ml-3 lg:hidden w-[95px]">
                Rezerwacja
                <br />
                wymiana opon
              </div>
              <div className="text-sm font-medium ml-3 w-[190px] hidden lg:block">
                Rezerwacja stanowiska <br />
                na wymianę opon
              </div>
            </div>
          </div>
          <div>
            <StepsIndicator
              step={step}
              setStep={(val) => {
                if (step !== 4) {
                  if (val === 1) {
                    window.scrollTo(0, 0);
                    setStep(val);
                  }
                  if (val === 2 && isValidServiceStep) {
                    window.scrollTo(0, 0);
                    setStep(val);
                  }
                  if (val === 3 && isValidServiceStep && isValidDateStep) {
                    window.scrollTo(0, 0);
                    setStep(val);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      {step !== 4 && step !== 1 && (
        <div className="lg:max-w-[1440px] pl-4 mx-auto mt-6">
          <button
            className="hidden lg:flex items-center text-textMain gap-1 text-sm font-semibold"
            onClick={() => {
              window.scrollTo(0, 0);
              setStep((prevState) => (prevState - 1 < 1 ? 1 : prevState - 1));
            }}
          >
            <img src={backArrow} className="h-[20px] w-[20px]" />
            Wstecz
          </button>
        </div>
      )}

      {step === 1 && (
        <ServicesStep
          setStepFormData={setFormDataByKey}
          setStep={() => {
            window.scrollTo(0, 0);
            setStep(2);
          }}
          formData={formData}
        />
      )}
      {step === 2 && (
        <DatesStep
          setStepFormData={setFormDataByKey}
          setStep={() => {
            window.scrollTo(0, 0);
            setStep(3);
          }}
          formData={formData}
        />
      )}
      {step === 3 && (
        <UserDataStep
          getPDFid={(val) => setPDFId(val)}
          formData={formData}
          setStepFormData={setFormDataByKey}
          setStep={() => {
            window.scrollTo(0, 0);
            setStep(4);
          }}
        />
      )}
      {step >= 4 && <ConfirmationStep formData={formData} PDFId={PDFId} />}
    </div>
  );
}

export default App;
