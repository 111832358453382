import moment, { Moment } from "moment";

import { CalendarArrowLeft, CalendarArrowRight } from "../../assets/icons";
import calendarSeparatorBorder from "../../assets/line-7.svg";
import emptyDates from "../../assets/emptyDates.svg";

import { DateCircle } from "./DateCircle";
import { getPolishMonthName } from "../../utils";
import { Button } from "../buttons/Button";

interface CalendarProps {
  setSelectedDate: (val: string) => void;
  selectedDate: string;
  dates: Moment[];
}

export const Calendar = ({ selectedDate, setSelectedDate, dates }: CalendarProps) => {
  const momentDate = moment(selectedDate, "DD-MM-YYYY HH:mm");
  const currentMonthDate = dates.filter(
    (item) => item.format("M") === moment(selectedDate, "DD-MM-YYYY HH:mm").format("M")
  );

  const selectedDateHours = currentMonthDate
    .filter(
      (item) =>
        item.format("DD-MM-YYYY") ===
        moment(selectedDate, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY")
    )
    .map((item) => item.format("HH:mm"));

  const prevMonthDate = dates.filter(
    (item) =>
      item.format("M") ===
      moment(selectedDate, "DD-MM-YYYY HH:mm").subtract(1, "M").format("M")
  );
  const nextMonthDate = dates.filter(
    (item) =>
      item.format("M") ===
      moment(selectedDate, "DD-MM-YYYY HH:mm").add(1, "M").format("M")
  );
  const startOfMonth = moment(selectedDate, "DD-MM-YYYY HH:mm").startOf("month").day();
  const endOfMonth = moment(selectedDate, "DD-MM-YYYY HH:mm").endOf("month").day();

  const getPrevDays = () => {
    return [...Array((startOfMonth === 0 ? 7 : startOfMonth) - 1).keys()]
      .reverse()
      .map((item) => {
        const prevMonth = moment(selectedDate, "DD-MM-YYYY HH:mm")
          .subtract(1, "M")
          .endOf("month");
        return prevMonth.subtract(item, "d").format("D");
      });
  };

  const getNextDays = () => {
    return [...Array(7 - +endOfMonth).keys()].map((item) => {
      const prevMonth = moment(selectedDate, "DD-MM-YYYY HH:mm")
        .add(1, "M")
        .startOf("month");
      return prevMonth.add(item, "d").format("D");
    });
  };

  const hasPrevDates =
    dates.find((item) => {
      return item.isBefore(moment(selectedDate, "DD-MM-YYYY HH:mm").subtract(1, "M"));
    }) !== undefined ||
    moment(selectedDate, "DD-MM-YYYY HH:mm").subtract(1, "M").format("M") >=
      moment().format("M");

  const hasNextDates =
    dates.filter(
      (item) => +item.format("M") > +moment(selectedDate, "DD-MM-YYYY HH:mm").format("M")
    ).length > 0;

  const setClosestDate = (day: number = 1): string => {
    const selectedDateWithoutTime = moment(selectedDate, "DD-MM-YYYY").valueOf();
    const targetDatePrev = selectedDateWithoutTime - day * 24 * 60 * 60 * 1000;
    const targetDateNext = selectedDateWithoutTime + day * 24 * 60 * 60 * 1000;

    const closestPrevDate = findClosestDate(targetDatePrev);
    const closestNextDate = findClosestDate(targetDateNext);

    if (closestPrevDate && closestNextDate) {
      const diffPrev = Math.abs(selectedDateWithoutTime - closestPrevDate.valueOf());
      const diffNext = Math.abs(selectedDateWithoutTime - closestNextDate.valueOf());
      return diffPrev >= diffNext
        ? closestPrevDate.format("DD-MM-YYYY HH:mm")
        : closestNextDate.format("DD-MM-YYYY HH:mm");
    } else if (closestNextDate) {
      return closestNextDate.format("DD-MM-YYYY HH:mm");
    } else if (closestPrevDate) {
      return closestPrevDate.format("DD-MM-YYYY HH:mm");
    } else {
      return setClosestDate(day + 1);
    }
  };

  const findClosestDate = (targetDate: number) => {
    return dates.find((date) => {
      const dateWithoutTime = date.clone().startOf("day").valueOf();
      return dateWithoutTime === targetDate;
    });
  };

  return (
    <div className="flex flex-col items-center lg:flex-row lg:items-start">
      <div className="pt-3 pb-3 w-full max-w-[400px] lg:p-9 lg:min-w-[450px] lg:max-w-[450px]">
        <div className="flex justify-between items-center mb-2 h-9 py-2 px-4">
          <div
            className="h-5 w-5 cursor-pointer"
            onClick={() => {
              if (hasPrevDates) {
                setSelectedDate(momentDate.subtract(1, "M").format("DD-MM-YYYY HH:mm"));
              }
            }}
          >
            <CalendarArrowLeft stroke={`${hasPrevDates ? "#344054" : "#98A2B3"}`} />
          </div>
          <div className="text-textMain font-bold">
            {getPolishMonthName(+momentDate.format("M"))} {momentDate.format("YYYY")}
          </div>
          <div
            className="h-5 w-5 cursor-pointer"
            onClick={() => {
              if (hasNextDates) {
                setSelectedDate(momentDate.add(1, "M").format("DD-MM-YYYY HH:mm"));
              }
            }}
          >
            <CalendarArrowRight stroke={`${hasNextDates ? "#344054" : "#98A2B3"}`} />
          </div>
        </div>
        <div className="calendar grid grid-cols-7 gap-2 w-full">
          {["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Nd"].map((item, index) => {
            return (
              <div
                key={"week-days-" + index}
                className=" justify-self-center text-center p-2 w-10 h-10"
              >
                {item}
              </div>
            );
          })}
          {getPrevDays().map((item, index) => {
            const isActive =
              prevMonthDate.filter((dates) => dates.format("D") === item.toString())
                .length !== 0;
            return (
              <>
                <DateCircle
                  onClick={(val) => {
                    if (isActive) {
                      setSelectedDate(
                        momentDate
                          .set("date", +val)
                          .subtract(1, "M")
                          .format("DD-MM-YYYY HH:mm")
                      );
                    }
                  }}
                  day={item}
                  key={"prev-" + index}
                  isActive={isActive}
                  isCurrent={false}
                  isSelected={false}
                  isDisabled={true}
                />
              </>
            );
          })}
          {Array.from(
            { length: moment(selectedDate, "DD-MM-YYYY HH:mm").daysInMonth() },
            (_, i) => i + 1
          ).map((item, index) => {
            return (
              <>
                <DateCircle
                  onClick={(val) => {
                    setSelectedDate(
                      momentDate.set("date", +val).format("DD-MM-YYYY HH:mm")
                    );
                  }}
                  day={item.toString()}
                  key={"current-" + index}
                  isActive={
                    currentMonthDate.filter(
                      (dates) => dates.format("D") === item.toString()
                    ).length !== 0
                  }
                  isSelected={momentDate.format("D") === item.toString()}
                  isCurrent={
                    moment().format("D") === item.toString() &&
                    momentDate.format("M") === moment().format("M")
                  }
                />
              </>
            );
          })}
          {getNextDays().map((item, index) => {
            const isActive =
              nextMonthDate.filter((dates) => dates.format("D") === item.toString())
                .length !== 0;
            return (
              <>
                <DateCircle
                  onClick={(val) => {
                    if (isActive) {
                      setSelectedDate(
                        momentDate
                          .set("date", +val)
                          .add(1, "M")
                          .format("DD-MM-YYYY HH:mm")
                      );
                    }
                  }}
                  day={item}
                  key={"next-" + index}
                  isActive={isActive}
                  isCurrent={false}
                  isSelected={false}
                  isDisabled={!isActive}
                />
              </>
            );
          })}
        </div>
      </div>

      <img src={calendarSeparatorBorder} className="hidden lg:block h-full" />

      {selectedDate && selectedDateHours.length === 0 && (
        <div className="border-t border-grayMain py-5 px-3 w-full lg:border-0 lg:p-10 lg:max-w-full flex items-center justify-center flex-col gap-4 lg:mt-24">
          <div className="h-12 w-12 flex shadow-summaryBtn rounded-lg justify-center items-center bg-white border border-graySecond">
            <img src={emptyDates} />
          </div>
          <div className="text-center">
            <div className="font-semibold">Brak terminów w tym dniu</div>
            <div className="text-textSecondary text-sm">
              Nie ma już dostępnych terminów wybierz inny termin
            </div>
            <Button
              onClick={() => {
                setSelectedDate(setClosestDate());
              }}
              btnClassName="!bg-blueMain mt-6"
            >
              Pokaż najbliższy wolny
            </Button>
          </div>
        </div>
      )}
      {selectedDate && selectedDateHours.length !== 0 && (
        <div className="border-t border-grayMain py-5 w-full lg:border-0 lg:p-10 lg:max-w-full">
          <div className="flex flex-col items-center">
            <div className="max-w-[600px] w-full lg:max-w-full">
              <div className="font-bold pl-4 lg:pl-0 lg:mb-6">Wybierz godzinę</div>
              <div className="flex gap-3 mt-3 no-scrollbar overflow-y-auto cursor-pointer lg:flex-wrap px-4">
                {(selectedDate ? selectedDateHours : []).map((item) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedDate(
                          momentDate
                            .set("hour", +item.split(":")[0])
                            .set("minute", +item.split(":")[1])
                            .format("DD-MM-YYYY HH:mm")
                        );
                      }}
                      key={"hour-" + item}
                      className={`rounded-lg px-5 py-2 font-bold lg:hover:opacity-70 ${
                        momentDate.format("HH:mm") === item
                          ? "bg-blueMain text-white "
                          : "text-textMain border border-grayMain"
                      }`}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
